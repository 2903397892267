
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import 'swiper/css';
@import 'swiper/css/pagination';


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-pagination {
  bottom: -10px; 
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #ffffff;
  opacity: 0.8;
}

.swiper-pagination-bullet-active {
  background: #007bff;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }

  .no-scrollbar {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
}
}

.image-zoom {
  transition: transform 0.3s ease;
}

.image-zoom:hover {
  transform: scale(1.05);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}